import { Create, SimpleForm, TextInput, NumberInput, DateTimeInput } from 'react-admin';
import Grid from '@mui/material/Grid';

const PromoCodeCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="description" label="Description of the promo code" style={{ width: '400px' }} />
            <TextInput source="code" label="Promo code" style={{ width: '400px' }} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <NumberInput source="discountPercentage" label="Percentage discount %" min={0} max={50} />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput source="discountAmount" label="Fixed discount CZK" min={0} max={500} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <NumberInput source="usageLimit" label="Limit of usage" min={0} max={1000} />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput source="usageUserLimit" label="Limit of user per user" min={0} max={1000} />
                </Grid>
            </Grid>
            <DateTimeInput source='expireAt' label="Expiration time" />
        </SimpleForm>
    </Create>
);

export default PromoCodeCreate;
