import { Admin, Resource } from 'react-admin';
import PostCreate from './posts/PostCreate';
import dataProvider from './data-providers';
import PostList from './posts/PostList';
import { authProvider } from './providers/auth-provider';
import PromoCodeList from './promo-codes/PromoCodeList';
import PromoCodeCreate from './promo-codes/PromoCodeCreate';

const App = () => (
    <Admin dataProvider={dataProvider} authProvider={authProvider} disableTelemetry>
        <Resource name="telegram-products" list={PostList} create={PostCreate} />
        <Resource name="promo-codes" list={PromoCodeList} create={PromoCodeCreate} />
    </Admin>
);

export default App;
