// CustomList.js
import React from 'react';
import { List, Datagrid, DateField, TextField, EditButton, DeleteButton, NumberField, ImageField } from 'react-admin';

const PostList = (props: any) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title"/>
            <TextField source="size"/>
            <TextField source="description"/>
            <NumberField source="price" />
            <ImageField source="image" title="title"
            sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }} />
            <DateField source='createdAt' label="Created at" />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default PostList;
