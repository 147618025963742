// CustomList.js
import React from 'react';
import { List, Datagrid, DateField, TextField, DeleteButton, NumberField } from 'react-admin';

const PromoCodeList = (props: any) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="description"/>
            <TextField source="code"/>
            <NumberField source="discountPercentage" />
            <NumberField source="discountAmount" />
            <NumberField source="usageLimit" />
            <NumberField source="usageUserLimit" />
            <DateField source='expireAt' label="Expires at" showTime />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default PromoCodeList;
