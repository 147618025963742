// src/dataProvider.js
import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('authToken');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const backendRestProvider = simpleRestProvider(API_URL, httpClient);

const uploadImage = (data: any) => {
    const formData = new FormData();
    if (data.image) {
        formData.append('image', data.image.rawFile);
    }
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('price', data.price);

    const token = localStorage.getItem('authToken');

    return fetch(`${API_URL}/telegram-products`, {
        method: 'POST',
        body: formData,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => response.json())
        .then(json => ({
            id: json.id,
            ...json,
        }));
};

const dataProvider = {
    ...backendRestProvider,
    create: (resource: any, params: any) => {
        if (resource === 'telegram-products' && params.data.image) {
            return uploadImage(params.data).then((result) => ({
                data: {
                    ...params.data,
                    id: result.id,
                    imagePath: result.imagePath,
                },
            }));
        }

        return backendRestProvider.create(resource, params);
    },
};

export default dataProvider;
