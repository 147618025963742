import { Create, SimpleForm, TextInput, ImageInput, ImageField, NumberInput, SelectInput, SelectArrayInput } from 'react-admin';

const bouquetSizes = [
    { id: 'xs', name: 'XS' },
    { id: 'S', name: 'S' },
    { id: 'm', name: 'M' },
    { id: 'l', name: 'L' },
    { id: 'xl', name: 'XL' },
];

// const targetAudiences = [
//     { id: 'mom', name: 'Mom' },
//     { id: 'sister', name: 'Sister' },
// ];

// const targetEvents = [
//     { id: 'birthday', name: 'Birthday' },
// ];

const PostCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title" style={{ width: '400px' }} />
            {/* <SelectInput source="size" choices={bouquetSizes} /> */}
            {/* <SelectArrayInput source="targetAudience" choices={targetAudiences} />
            <SelectArrayInput source="targetEvent" choices={targetEvents} /> */}
            <TextInput source="description" style={{ width: '400px' }} />
            <NumberInput source="price" />
            <ImageInput source="image" label="Upload Image" accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
);

export default PostCreate;
