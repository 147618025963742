interface ILoginCredentials {
    readonly username: string;
    readonly password: string;
}

interface IErrorInterface {
    readonly status: number;
}

const API_URL = `${process.env.REACT_APP_API_URL}/api/auth`;
const loginUrl = `${API_URL}/login`;
const checkUrl = `${API_URL}/check-auth`;

export const authProvider = {
    login: (loginCredentials: ILoginCredentials) => {
        const { username, password } = loginCredentials;
        const request = new Request(loginUrl, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                const {token} = data;
                localStorage.setItem('authToken', token);
            });
    },
    logout: () => {
        localStorage.removeItem('authToken');
        return Promise.resolve();
    },
    checkAuth: () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            return Promise.reject();
        }
        const request = new Request(checkUrl, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    localStorage.removeItem('authToken');
                    throw new Error(response.statusText);
                }
                return Promise.resolve();
            })
            .catch(() => {
                localStorage.removeItem('authToken');
                return Promise.reject();
            });
    },
    checkError: (error: IErrorInterface) => {
        const { status } = error;
        const failedLoginCodes = [401, 403];

        if (failedLoginCodes.includes(status)) {
            localStorage.removeItem('authToken');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => Promise.resolve(),
};
